import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Device } from '../App';
import { DeviceWithLogs } from '../DeviceDetails';
import { deviceService } from '../services/api';

interface UseDevicesResult {
  devices: Device[];
  loading: boolean;
  error: Error | null;
  refreshDevices: () => Promise<void>;
  addDevice: (device: Device) => Promise<void>;
  updateDevice: (id: string, device: Partial<Device>) => Promise<void>;
  deleteDevice: (id: string) => Promise<void>;
  getDevice: (id: string) => Promise<DeviceWithLogs>;
  toggleListSort: () => void;
  devicesSort: 'default' | 'location';
  setDevicesSort: (sort: 'default' | 'location') => void;
}

export function useDevices(): UseDevicesResult {
  const [originalDevices, setOriginalDevices] = useState<Device[]>([]);

  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [devicesSort, setDevicesSort] = useState<'default' | 'location'>(() => {
    // Initialize from localStorage if available
    const savedSort = localStorage.getItem('devicesSort');
    return (savedSort as 'default' | 'location') || 'default';
  });

  const navigate = useNavigate();

  // Separate function to handle sorting
  const sortDevices = (devices: Device[], sortType: 'default' | 'location') => {
    console.log('Sorting devices with type:', sortType);
    if (sortType === 'location') {
      const filteredDevices = devices.filter((device) => device.lastSeenCity);

      return [...filteredDevices].sort((a, b) => {
        const cityA = a.lastSeenCity || '';
        const cityB = b.lastSeenCity || '';
        return cityA.localeCompare(cityB);
      });
    }
    return [...devices];
  };

  const toggleListSort = useCallback(() => {
    const nextSort = devicesSort === 'default' ? 'location' : 'default';
    console.log('Toggling sort from', devicesSort, 'to', nextSort);
    localStorage.setItem('devicesSort', nextSort); // Save to localStorage
    setDevicesSort(nextSort);
    const sortedDevices = sortDevices(originalDevices, nextSort);
    console.log('Setting sorted devices:', sortedDevices);
    setDevices(sortedDevices);
  }, [devicesSort, originalDevices]);

  const refreshDevices = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Refreshing devices, current sort type:', devicesSort);
      const data = await deviceService.getAllDevices();
      console.log('Received new data from API');

      setOriginalDevices(data);
      const sortedDevices = sortDevices(data, devicesSort);
      console.log('Applied sort, setting devices:', sortedDevices);
      setDevices(sortedDevices);
      setError(null);
    } catch (err) {
      if (err === 'Unauthorized') {
        console.log('Unauthorized');
        localStorage.removeItem('token');
        navigate('/account');
      }
      setError(err instanceof Error ? err : new Error('Failed to fetch devices'));
    } finally {
      setLoading(false);
    }
  }, [devicesSort, navigate]);

  const getDevice = async (id: string) => {
    try {
      return await deviceService.getDevice(id);
    } catch (err) {
      if (err === 'Unauthorized') {
        console.log('Unauthorized');
        localStorage.removeItem('token');
        navigate('/account');
      }

      throw err instanceof Error ? err : new Error('Failed to fetch device');
    }
  };

  const addDevice = async (device: Device) => {
    try {
      await deviceService.createDevice(device);
      await refreshDevices();
    } catch (err) {
      if (err === 'Unauthorized') {
        console.log('Unauthorized');
        localStorage.removeItem('token');
        navigate('/account');
      }
      throw err instanceof Error ? err : new Error('Failed to add device');
    }
  };

  const updateDevice = async (id: string, device: Partial<Device>) => {
    try {
      await deviceService.updateDevice(id, device);
      await refreshDevices();
    } catch (err) {
      if (err === 'Unauthorized') {
        console.log('Unauthorized');
        localStorage.removeItem('token');
        navigate('/account');
      }
      throw err instanceof Error ? err : new Error('Failed to update device');
    }
  };

  const deleteDevice = async (id: string) => {
    try {
      await deviceService.deleteDevice(id);
      await refreshDevices();
    } catch (err) {
      if (err === 'Unauthorized') {
        console.log('Unauthorized');
        localStorage.removeItem('token');
        navigate('/account');
      }
      throw err instanceof Error ? err : new Error('Failed to delete device');
    }
  };

  // Effect to re-sort when devicesSort changes
  useEffect(() => {
    console.log('devicesSort changed to:', devicesSort);
    const sortedDevices = sortDevices(originalDevices, devicesSort);
    setDevices(sortedDevices);
  }, [devicesSort, originalDevices]);

  useEffect(() => {
    refreshDevices();
    // Set up polling interval
    const interval = setInterval(refreshDevices, 10000);
    return () => clearInterval(interval);
  }, [refreshDevices]);

  return {
    devices,
    loading,
    error,
    refreshDevices,
    addDevice,
    updateDevice,
    deleteDevice,
    getDevice,
    toggleListSort,
    devicesSort,
    setDevicesSort
  };
}
