import { AnimatePresence, motion } from 'framer-motion';
import {
    Activity,
    Bell,
    Bluetooth,
    Cpu,
    Info,
    Radio,
    Network,
    Shield,
    Server,
    Scan,
    Camera,
    X,
    Repeat
} from 'lucide-react';
import styles from './DeviceForm.module.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import jsQR from 'jsqr';
import { availableBeaconConfigs, availableGatewayConfigs } from '../const';
import Fuse from 'fuse.js';

import { allKPopArtists, groupImages } from '../kpop';

const kpopArtists = allKPopArtists.filter((artist) => {
    const availableGroupImages = groupImages.map((group) =>
        group.name.toLowerCase()
    );
    return availableGroupImages.includes(artist.Group.toLowerCase());
});

// DeviceTypeSelector Component
//@ts-ignore
const DeviceTypeSelector = ({ selectedType, onTypeChange }) => (
    <div className={styles.formSection}>
        <div className={styles.deviceTypeSelector}>
            <button
                type="button"
                className={`${styles.deviceTypeButton} ${selectedType === 'beacon' ? styles.selected : ''}`}
                onClick={() => onTypeChange('beacon')}>
                <span className={styles.deviceTypeIcon}>
                    <Bluetooth size={24} />
                </span>
                <span>Beacon</span>
            </button>
            <button
                type="button"
                className={`${styles.deviceTypeButton} ${selectedType === 'gateway' ? styles.selected : ''}`}
                onClick={() => onTypeChange('gateway')}>
                <span className={styles.deviceTypeIcon}>
                    <Network size={24} />
                </span>
                <span>Gateway</span>
            </button>
        </div>
    </div>
);

// MAC Address Input Component
//@ts-ignore
const MacAddressInput = ({ value, onChange, className, required = false }) => {
    const [isScanning, setIsScanning] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const animationFrameRef = useRef(null);
    // @ts-ignore
    const formatMacAddress = (input) => {
        const cleaned = input.replace(/[^0-9A-Fa-f]/g, '');
        const formatted = cleaned.match(/.{1,2}/g)?.join(':') || cleaned;
        return formatted.slice(0, 17).toUpperCase();
    };

    const stopScanning = useCallback(() => {
        if (streamRef.current) {
            // @ts-ignore
            streamRef.current.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        setIsScanning(false);
    }, []);

    useEffect(() => {
        return () => {
            stopScanning();
        };
    }, [stopScanning]);

    const scanQRCode = useCallback(async () => {
        if (!videoRef.current || !canvasRef.current) return;
        const canvas = canvasRef.current;
        const video = videoRef.current;
        // @ts-ignore
        const context = canvas.getContext('2d', { willReadFrequently: true });
        // @ts-ignore
        if (video.readyState !== video.HAVE_ENOUGH_DATA) {
            // @ts-ignore
            animationFrameRef.current = requestAnimationFrame(scanQRCode);
            return;
        }

        // Set canvas size to match video
        // @ts-ignore
        canvas.width = video.videoWidth;
        // @ts-ignore
        canvas.height = video.videoHeight;

        // Draw video frame to canvas
        // @ts-ignore
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
            const imageData = context.getImageData(
                0,
                0,
                // @ts-ignore
                canvas.width,
                // @ts-ignore
                canvas.height
                // @ts-ignore
            );
            const code = jsQR(
                imageData.data,
                imageData.width,
                imageData.height
            );

            if (code) {
                console.log('QR code detected:', code.data);
                // Verify if the QR code content matches MAC address format
                const macAddressRegex =
                    /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
                if (macAddressRegex.test(code.data)) {
                    onChange(code.data);
                    stopScanning();
                    return;
                }

                // If it's not in MAC format, try to clean it up
                const cleanedMac = formatMacAddress(code.data);
                if (cleanedMac.length === 17) {
                    onChange(cleanedMac);
                    stopScanning();
                    return;
                }
            }
        } catch (error) {
            console.error('QR scanning error:', error);
        }

        // Continue scanning if no valid QR code found
        if (isScanning) {
            // @ts-ignore
            animationFrameRef.current = requestAnimationFrame(scanQRCode);
        }
    }, [isScanning, onChange, stopScanning]);

    const handleQRScan = async () => {
        try {
            // Try environment facing camera first (for mobile devices)
            let stream;
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'environment' }
                });
            } catch {
                // If environment camera fails, try user facing camera
                stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'user' }
                });
            }
            // @ts-ignore
            streamRef.current = stream;

            if (videoRef.current) {
                // @ts-ignore
                videoRef.current.srcObject = stream;
                // Wait for video to be ready
                await new Promise((resolve) => {
                    // @ts-ignore
                    videoRef.current.onloadedmetadata = () => {
                        // @ts-ignore
                        resolve();
                    };
                });
                // @ts-ignore
                await videoRef.current.play();
                scanQRCode();
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
            alert('Unable to access camera. Please check permissions.');
            setIsScanning(false);
        }
    };

    const startScanning = () => {
        setIsScanning(true);
    };

    useEffect(() => {
        if (isScanning) {
            handleQRScan();
        }
    }, [isScanning]);

    return (
        <div
            className={styles.macAddressInputWrapper}
            style={{ marginBottom: isScanning ? '350px' : '0px' }}>
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(formatMacAddress(e.target.value))}
                className={`${className} ${styles.macAddressInput}`}
                placeholder="XX:XX:XX:XX:XX:XX"
                pattern="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$"
                required={required}
            />
            <button
                type="button"
                onClick={startScanning}
                className={styles.qrScanButton}
                title="Scan QR Code">
                <Camera size={20} />
            </button>

            {isScanning && (
                <div className={styles.qrScannerModal}>
                    <div className={styles.qrScannerContent}>
                        <div className={styles.qrScannerHeader}>
                            <h3>Scan QR Code</h3>
                            <button
                                type="button"
                                onClick={stopScanning}
                                className={styles.closeButton}>
                                <X size={24} />
                            </button>
                        </div>

                        <div className={styles.qrScannerViewfinder}>
                            <video
                                ref={videoRef}
                                className={styles.qrVideo}
                                playsInline
                                muted
                                autoPlay
                            />
                            <canvas
                                ref={canvasRef}
                                className={styles.qrCanvas}
                            />
                            <div className={styles.scannerOverlay}>
                                <div className={styles.scannerTarget} />
                            </div>
                        </div>

                        <p className={styles.qrScannerInstructions}>
                            Position the QR code within the frame
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

// @ts-ignore
const BeaconBasicSection = ({ data, setData, allDevices }) => {
    const [suggestedBeaconBrands, setSuggestedBeaconBrands] = useState([]);
    const [suggestedBeaconModels, setSuggestedBeaconModels] = useState([]);
    const [brandInput, setBrandInput] = useState(data.brand || '');
    const [modelInput, setModelInput] = useState(data.modelName || '');

    useEffect(() => {
        // Search for beacon models based on selected brand
        const selectedBrandConfig = availableBeaconConfigs.find(
            (config) => config.name.toLowerCase() === brandInput.toLowerCase()
        );
        if (selectedBrandConfig && selectedBrandConfig.beaconModels) {
            const models = selectedBrandConfig.beaconModels.map(
                // @ts-ignore
                (model) => model.modelName
            );

            // @ts-ignore
            setSuggestedBeaconModels(models);
        } else {
            setSuggestedBeaconModels([]);
        }
    }, [brandInput]);
    // @ts-ignore
    const handleBrandChange = (e) => {
        const value = e.target.value;
        setBrandInput(value);
        setData({ ...data, brand: value });
    };
    // @ts-ignore
    const handleModelChange = (e) => {
        const value = e.target.value;
        setModelInput(value);
        setData({ ...data, modelName: value });
    };
    // @ts-ignore
    const handleBrandSelect = (brand) => {
        setBrandInput(brand);
        setData({ ...data, brand });
        // Trigger your function here
    };
    // @ts-ignore
    const handleModelSelect = (model) => {
        setModelInput(model);
        const brandModels = availableBeaconConfigs.find(
            (config) => config.name.toLowerCase() === data.brand.toLowerCase()
        )?.beaconModels;
        // @ts-ignore
        const selectedModel = brandModels.find(
            // @ts-ignore
            (config) => config.modelName.toLowerCase() === model.toLowerCase()
        );
        if (selectedModel) {
            setData({ ...data, ...selectedModel, brand: brandInput });
        } else {
            setData({ ...data, modelName: model });
        }
    };

    type kpopArtist = {
        'Stage Name': string;
        'Full Name': string;
        'Korean Name': string;
        'K Stage Name': string;
        'Date of Birth': string;
        Group: string;
        Debut: string;
        Company: string;
        Country: string;
        'Second Country': string;
        Height: string;
        Weight: string;
        Birthplace: string;
        'Other Group': string;
        'Former Group': string;
        Gender: string;
        'Image URL'?: string;
    };
    const [kpopArtist, setKpopArtist] = useState() as [kpopArtist, any];

    const pickRandomKpopArtist = () => {
        //@ts-ignore
        const allDevicesNames = allDevices.map((device) => device.name);
        const filteredKpopArtists = kpopArtists.filter(
            (artist) => !allDevicesNames.includes(artist['Stage Name'])
        );
        const randomIndex = Math.floor(
            Math.random() * filteredKpopArtists.length
        );
        const randomArtist = filteredKpopArtists[randomIndex];
        setData({ ...data, name: randomArtist['Stage Name'] });
        setKpopArtist(randomArtist);
    };

    useEffect(() => {
        setBrandInput(data.brand);
        setModelInput(data.modelName);

        // Search for beacon brands
        const brands = availableBeaconConfigs.map((config) => config.name);
        // @ts-ignore
        setSuggestedBeaconBrands(brands);

        // check if name matches existing kpop artist
        const matchedKpopArtist = kpopArtists.find(
            (artist) =>
                artist['Stage Name'].toLowerCase() === data.name.toLowerCase()
        );
        if (matchedKpopArtist) {
            setKpopArtist(matchedKpopArtist);
        }
    }, []);

    return (
        <div className={styles.formSection}>
            <div className={styles.formGrid}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Name
                        <input
                            type="text"
                            value={data.name}
                            onChange={(e) =>
                                setData({ ...data, name: e.target.value })
                            }
                            className={styles.input}
                            placeholder="MeiiMeii"
                            required
                        />
                        <button
                            type="button"
                            onClick={pickRandomKpopArtist}
                            className={styles.qrScanButton}
                            style={{ marginTop: '8px' }}
                            title="Scan QR Code">
                            <Repeat size={20} />
                        </button>
                    </label>
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={kpopArtist?.['Stage Name']}
                        initial={{
                            opacity: 0,
                            y: 20
                        }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                        className={styles.formField}>
                        {kpopArtist && (
                            <div className={styles.kpopArtistCard}>
                                <div className={styles.kpopArtistImg}>
                                    <img
                                        src={
                                            kpopArtist['Image URL']
                                                ? kpopArtist['Image URL']
                                                : groupImages.find(
                                                      (group) =>
                                                          group.name.toLowerCase() ===
                                                          kpopArtist.Group.toLowerCase()
                                                  )?.imageUrl
                                        }
                                        alt={kpopArtist['Stage Name']}
                                    />
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <h3>{kpopArtist['Stage Name']}</h3>
                                    <p className={styles.fullName}>
                                        {kpopArtist['Full Name']}
                                    </p>
                                    <p className={styles.koreanName}>
                                        {kpopArtist['Korean Name']}
                                    </p>
                                    <p
                                        style={{
                                            fontFamily: 'Geist Bold'
                                        }}
                                        className={styles.groupName}>
                                        {kpopArtist['Group']}
                                    </p>
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <p>📏 {kpopArtist['Height']}</p>
                                    <p>{kpopArtist['Weight']}</p>
                                    <p>🐣 {kpopArtist['Debut']}</p>
                                    <p>📍 {kpopArtist['Country']}</p>
                                    {/* <p>{kpopArtist['Second Country']}</p> */}
                                    <p>👼🏼 {kpopArtist['Birthplace']}</p>
                                </div>
                                {/* <p>{kpopArtist['Other Group']}</p> */}
                                {/* <p>{kpopArtist['Former Group']}</p> */}
                                {/* <p>{kpopArtist.Gender}</p> */}
                            </div>
                        )}
                    </motion.div>
                </AnimatePresence>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        MAC Address
                        <MacAddressInput
                            value={data.macAddress}
                            onChange={(value: any) =>
                                setData({ ...data, macAddress: value })
                            }
                            className={styles.input}
                            required
                        />
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Brand
                        <input
                            type="text"
                            value={data.brand}
                            onChange={handleBrandChange}
                            className={styles.input}
                            placeholder="Minew Tech"
                            required
                        />
                        {suggestedBeaconBrands.length > 0 && (
                            <div className={styles.suggestions}>
                                {suggestedBeaconBrands
                                    .filter((brand) =>
                                        brand // @ts-ignore
                                            .toLowerCase()
                                            .includes(brandInput.toLowerCase())
                                    )
                                    .map((brand) => (
                                        <p
                                            key={brand}
                                            onClick={() =>
                                                handleBrandSelect(brand)
                                            }>
                                            {brand}
                                        </p>
                                    ))}
                            </div>
                        )}
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Model
                        <input
                            type="text"
                            value={data.modelName}
                            onChange={handleModelChange}
                            required
                            className={styles.input}
                            placeholder="E2 - Beacon Plus-LB"
                        />
                        {suggestedBeaconModels &&
                            suggestedBeaconModels.length > 0 && (
                                <div className={styles.suggestions}>
                                    {suggestedBeaconModels
                                        .filter((model) =>
                                            model // @ts-ignore
                                                .toLowerCase()
                                                .includes(
                                                    modelInput.toLowerCase()
                                                )
                                        )
                                        .map((model) => (
                                            <p
                                                key={model}
                                                onClick={() =>
                                                    handleModelSelect(model)
                                                }>
                                                {model}
                                            </p>
                                        ))}
                                </div>
                            )}
                    </label>
                </div>

                {/* <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        Linked Item
                        <input
                            type="text"
                            value={data.linkedItemId}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    linkedItemId: e.target.value
                                })
                            }
                            className={styles.input}
                            style={{ height: '20px' }}
                            placeholder="21456326546743"
                        />
                    </label>
                </div> */}
            </div>
        </div>
    );
};
//@ts-ignore
const LinkedItemSection = ({ data, setData }) => {
    // const [results, setResults] = useState<Item[]>([]);

    const [search, setSearch] = useState('');

    const [loading, setLoading] = useState(false);

    const [allItems, setAllItems] = useState([]);

    const [results, setResults] = useState([]);

    const getAllItems = async () => {
        const response = await fetch(
            'https://meii.minji.pack.wtf/api/items/all',
            {
                headers: {
                    Authorization: `${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.ok) {
            const items = await response.json();
            const a = items.things.concat(items.bags);
            setAllItems(a);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllItems();
    }, []);

    const getResults = async (search: string) => {
        if (!allItems || allItems.length < 1) {
            setLoading(true);
            return;
        }
        const options = {
            keys: ['name', 'description'],
            includeScore: true
        };

        const fuse = new Fuse(allItems, options);
        const result = fuse.search(search);

        console.log(result);

        setResults(result.map((item) => item.item));
        setLoading(false);
    };

    const handleSearch = (e: any) => {
        setLoading(true);
        console.log(e.target.value);
        setSearch(e.target.value);
        if (e.target.value === '') {
            setLoading(false);
        }
    };

    const searchRef = useRef(search);

    useEffect(() => {
        searchRef.current = search;
    }, [search]);

    useEffect(() => {
        // Vibrate for 200ms on page load
        if (typeof window !== 'undefined' && 'vibrate' in navigator) {
            navigator.vibrate(200);
        }
    }, []);

    useEffect(() => {
        const handler = setTimeout(async () => {
            console.log('Search: ', search);
            if (search === searchRef.current) {
                if (search === '') {
                    // setSearch('');
                    setLoading(false);
                    return;
                }
                getResults(search);
            }
        }, 1200);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    // const handleProductChange = (e) => {
    //     const value = e.target.value;
    //     setProductInput(value);
    //     setData({ ...data, linkedProductId: value });
    // };

    // const handleProductSelect = (product) => {
    //     setProductInput(product);
    //     setData({ ...data, linkedProductId: product });
    // };

    return (
        <div className={styles.formSection}>
            <div className={styles.formGrid}>
                <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        Linked Item
                        <input
                            type="text"
                            value={data.linkedItemId}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    linkedItemId: e.target.value
                                })
                            }
                            className={styles.input}
                            placeholder="21456326546743"
                        />
                    </label>
                </div>
                {/* search input */}
                <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        Search
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearch}
                            className={styles.input}
                            placeholder="Search for item"
                        />
                    </label>

                    {loading && <p>Loading...</p>}

                    <div className={styles.suggestions}>
                        {results.map((item) => (
                            <div style={{ display: 'flex' }}>
                                <img
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        objectFit: 'cover'
                                    }}
                                    // @ts-ignore
                                    src={item.primaryImage}
                                    // @ts-ignore
                                    alt={item.name}
                                />
                                <p
                                    // @ts-ignore
                                    key={item.id}
                                    onClick={() =>
                                        setData({
                                            ...data,
                                            // @ts-ignore
                                            linkedItemId: item.id
                                        })
                                    }>
                                    {/* @ts-ignore */}
                                    {item.name}
                                </p>
                            </div>
                        ))}

                        {/* {results.length === 0 && !loading && (
                            <p>No results found</p>
                        )}

                        {loading && <p>Loading...</p>}

                        {search.length < 1 && (
                            <p>Start typing to search for items</p>
                        )}

                        {search.length > 0 &&
                            !loading &&
                            results.length < 1 && <p>No results found</p>} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

//@ts-ignore
const IBeaconSection = ({ data, setData }) => (
    <div className={styles.formSection} style={{ overflowY: 'scroll' }}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Protocol
                    <input
                        type="text"
                        value={data.protocol}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    UUID
                    <input
                        type="text"
                        value={data.uuid}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Advertisement Interval (ms)
                    <input
                        type="number"
                        value={data.advInterval}
                        onChange={(e) =>
                            setData({
                                ...data,
                                advInterval: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="100"
                        max="10000"
                        required
                    />
                </label>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Major
                        <input
                            type="number"
                            value={data.major}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    major: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="0"
                            max="65535"
                            style={{ width: '75%' }}
                            required
                        />
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Minor
                        <input
                            type="number"
                            value={data.minor}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    minor: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="0"
                            max="65535"
                            style={{ width: '75%' }}
                            required
                        />
                    </label>
                </div>
            </div>
        </div>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    TX Power Unit
                    <input
                        type="text"
                        value={data.txPowerUnit}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        TX Power
                        <input
                            type="number"
                            value={data.txPower}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    txPower: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            style={{ width: '75%' }}
                            min="-40"
                            max="4"
                            required
                        />
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        RSSI at 1m
                        <input
                            type="number"
                            value={data.rssiAt1m}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    rssiAt1m: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="-100"
                            style={{ width: '75%' }}
                            max="0"
                            required
                        />
                    </label>
                </div>
            </div>
        </div>
    </div>
);
//@ts-ignore
const BeaconConfigSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Password/Pin Code
                    <input
                        type="text"
                        value={data.password}
                        onChange={(e) =>
                            setData({ ...data, password: e.target.value })
                        }
                        className={styles.input}
                        placeholder="minew123"
                        required
                    />
                </label>
            </div>
        </div>

        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.connectable}
                    onChange={(e) =>
                        setData({ ...data, connectable: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                Connectable
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.powerOnAlways}
                    onChange={(e) =>
                        setData({ ...data, powerOnAlways: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                Power On Always
            </label>
        </div>
    </div>
);

//@ts-ignore
const BeaconFramesConfig = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={true}
                    disabled
                    className={styles.checkbox}
                />
                iBeacon
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.tlmFrame}
                    onChange={(e) =>
                        setData({ ...data, tlmFrame: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                TLM
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.infoFrame}
                    onChange={(e) =>
                        setData({ ...data, infoFrame: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                DeviceInfo
            </label>
        </div>
    </div>
);
//@ts-ignore
const BeaconTriggersSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.triggers.motion}
                    onChange={(e) =>
                        setData({
                            ...data,
                            triggers: {
                                ...data.triggers,
                                motion: e.target.checked
                            }
                        })
                    }
                    className={styles.checkbox}
                />
                Motion Trigger
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.triggers.button}
                    onChange={(e) =>
                        setData({
                            ...data,
                            triggers: {
                                ...data.triggers,
                                button: e.target.checked
                            }
                        })
                    }
                    className={styles.checkbox}
                />
                Button Trigger
            </label>
        </div>
    </div>
);
//@ts-ignore
const BeaconVersionSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Software Version
                    <input
                        type="text"
                        value={data.softwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                softwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="nRF52-SDK13.0"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Firmware Version
                    <input
                        type="text"
                        value={data.firmwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                firmwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="2.3.12"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Hardware Version
                    <input
                        type="text"
                        value={data.hardwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                hardwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="MS71SF6_V1.0.0"
                    />
                </label>
            </div>
        </div>
    </div>
);

// Gateway Form Sections//@ts-ignore
//@ts-ignore
const GatewayBasicSection = ({ data, setData, allDevices }) => {
    type kpopArtist = {
        'Stage Name': string;
        'Full Name': string;
        'Korean Name': string;
        'K Stage Name': string;
        'Date of Birth': string;
        Group: string;
        Debut: string;
        Company: string;
        Country: string;
        'Second Country': string;
        Height: string;
        Weight: string;
        Birthplace: string;
        'Other Group': string;
        'Former Group': string;
        Gender: string;
        'Image URL'?: string;
    };
    const [kpopArtist, setKpopArtist] = useState() as [kpopArtist, any];

    const pickRandomKpopArtist = () => {
        //@ts-ignore
        const allDevicesNames = allDevices.map((device) => device.name);
        const filteredKpopArtists = kpopArtists.filter(
            (artist) => !allDevicesNames.includes(artist['Stage Name'])
        );
        const randomIndex = Math.floor(
            Math.random() * filteredKpopArtists.length
        );
        const randomArtist = filteredKpopArtists[randomIndex];
        setData({ ...data, name: randomArtist['Stage Name'] });
        setKpopArtist(randomArtist);
    };

    useEffect(() => {
        // check if name matches existing kpop artist
        const matchedKpopArtist = kpopArtists.find(
            (artist) =>
                artist['Stage Name'].toLowerCase() === data.name.toLowerCase()
        );
        if (matchedKpopArtist) {
            setKpopArtist(matchedKpopArtist);
        }
    }, []);
    return (
        <div className={styles.formSection}>
            <div className={styles.formGrid}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Name
                        <input
                            type="text"
                            value={data.name}
                            onChange={(e) =>
                                setData({ ...data, name: e.target.value })
                            }
                            className={styles.input}
                            placeholder="MeiiMeii"
                            required
                        />
                        <button
                            type="button"
                            onClick={pickRandomKpopArtist}
                            className={styles.qrScanButton}
                            style={{ marginTop: '8px' }}
                            title="Scan QR Code">
                            <Repeat size={20} />
                        </button>
                    </label>
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={kpopArtist?.['Stage Name']}
                        initial={{
                            opacity: 0,
                            y: 20
                        }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                        className={styles.formField}>
                        {kpopArtist && (
                            <div className={styles.kpopArtistCard}>
                                <div className={styles.kpopArtistImg}>
                                    <img
                                        src={
                                            kpopArtist['Image URL']
                                                ? kpopArtist['Image URL']
                                                : groupImages.find(
                                                      (group) =>
                                                          group.name.toLowerCase() ===
                                                          kpopArtist.Group.toLowerCase()
                                                  )?.imageUrl
                                        }
                                        alt={kpopArtist['Stage Name']}
                                    />
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <h3>{kpopArtist['Stage Name']}</h3>
                                    <p>{kpopArtist['Full Name']}</p>
                                    <p>{kpopArtist['Korean Name']}</p>
                                    <p
                                        style={{
                                            fontFamily: 'Geist Bold'
                                        }}>
                                        {kpopArtist['Group']}
                                    </p>
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <p>📏 {kpopArtist['Height']}</p>
                                    <p>{kpopArtist['Weight']}</p>
                                    <p>🐣 {kpopArtist['Debut']}</p>
                                    <p>📍 {kpopArtist['Country']}</p>
                                    {/* <p>{kpopArtist['Second Country']}</p> */}
                                    <p>👼🏼 {kpopArtist['Birthplace']}</p>
                                </div>
                                {/* <p>{kpopArtist['Other Group']}</p> */}
                                {/* <p>{kpopArtist['Former Group']}</p> */}
                                {/* <p>{kpopArtist.Gender}</p> */}
                            </div>
                        )}
                    </motion.div>
                </AnimatePresence>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        MAC Address
                        <MacAddressInput
                            value={data.macAddress}
                            onChange={(value: any) =>
                                setData({ ...data, macAddress: value })
                            }
                            className={styles.input}
                            required
                        />
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        Linked Item
                        <input
                            type="text"
                            value={data.linkedItemId}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    linkedItemId: e.target.value
                                })
                            }
                            className={styles.input}
                            style={{ height: '20px' }}
                            placeholder="21456326546743"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

//@ts-ignore
const GatewayDeviceInfoSection = ({ data, setData }) => {
    const [suggestedGatewayBrands, setSuggestedGatewayBrands] = useState([]);
    const [suggestedGatewayModels, setSuggestedGatewayModels] = useState([]);

    const [brandInput, setBrandInput] = useState(data.brand || '');
    const [modelInput, setModelInput] = useState(data.modelName || '');

    useEffect(() => {
        // Search for beacon models based on selected brand
        const selectedBrandConfig = availableGatewayConfigs.find(
            (config) => config.name.toLowerCase() === brandInput.toLowerCase()
        );
        if (selectedBrandConfig && selectedBrandConfig.gatewayModels) {
            const models = selectedBrandConfig.gatewayModels.map(
                // @ts-ignore
                (model) => model.modelName
            );

            // @ts-ignore
            setSuggestedGatewayModels(models);
        } else {
            setSuggestedGatewayModels([]);
        }
    }, [brandInput]);
    // @ts-ignore
    const handleBrandChange = (e) => {
        const value = e.target.value;
        setBrandInput(value);
        setData({ ...data, brand: value });
    };
    // @ts-ignore
    const handleModelChange = (e) => {
        const value = e.target.value;
        setModelInput(value);
        setData({ ...data, modelName: value });
    };
    // @ts-ignore
    const handleBrandSelect = (brand) => {
        setBrandInput(brand);
        setData({ ...data, brand });
        // Trigger your function here
    };
    // @ts-ignore
    const handleModelSelect = (model) => {
        setModelInput(model);
        const brandModels = availableGatewayConfigs.find(
            (config) =>
                config.name.toLowerCase() === brandInput.toLowerCase() &&
                config.gatewayModels.some(
                    // @ts-ignore
                    (m) => m.modelName.toLowerCase() === model.toLowerCase()
                )
        );
        const selectedModel = brandModels?.gatewayModels.find(
            // @ts-ignore
            (m) => m.modelName.toLowerCase() === model.toLowerCase()
        );

        console.log(selectedModel);
        if (selectedModel) {
            setData({
                ...data,
                ...selectedModel,
                brand: brandInput,
                macAddress: data.macAddress,
                name: data.name,
                linkedProductId: data.linkedProductId
            });
        } else {
            setData({ ...data, modelName: model });
        }
    };

    useEffect(() => {
        setBrandInput(data.brand);
        setModelInput(data.modelName);

        // Search for beacon brands
        const brands = availableGatewayConfigs.map((config) => config.name);
        // @ts-ignore
        setSuggestedGatewayBrands(brands);
    }, []);
    return (
        <div className={styles.formSection}>
            <div className={styles.formGrid}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Brand
                        <input
                            type="text"
                            value={data.brand}
                            onChange={handleBrandChange}
                            className={styles.input}
                            placeholder="Minew Tech"
                            required
                        />
                        {suggestedGatewayBrands.length > 0 && (
                            <div className={styles.suggestions}>
                                {suggestedGatewayBrands
                                    .filter((brand) =>
                                        brand // @ts-ignore
                                            .toLowerCase()
                                            .includes(brandInput.toLowerCase())
                                    )
                                    .map((brand) => (
                                        <p
                                            key={brand}
                                            onClick={() =>
                                                handleBrandSelect(brand)
                                            }>
                                            {brand}
                                        </p>
                                    ))}
                            </div>
                        )}
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Model
                        <input
                            type="text"
                            value={data.modelName}
                            onChange={handleModelChange}
                            required
                            className={styles.input}
                            placeholder="E2 - Beacon Plus-LB"
                        />
                        {suggestedGatewayModels &&
                            suggestedGatewayModels.length > 0 && (
                                <div className={styles.suggestions}>
                                    {suggestedGatewayModels
                                        .filter((model) =>
                                            model // @ts-ignore
                                                .toLowerCase()
                                                .includes(
                                                    modelInput.toLowerCase()
                                                )
                                        )
                                        .map((model) => (
                                            <p
                                                key={model}
                                                onClick={() =>
                                                    handleModelSelect(model)
                                                }>
                                                {model}
                                            </p>
                                        ))}
                                </div>
                            )}
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        MCU Model
                        <input
                            type="text"
                            value={data.mcuModel}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    mcuModel: e.target.value
                                })
                            }
                            className={styles.input}
                            style={{ height: '20px' }}
                            placeholder="ESP32"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

//@ts-ignore
const ScanningSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Upload Interval (seconds)
                    <input
                        type="number"
                        value={data.uploadIntervalSeconds}
                        onChange={(e) =>
                            setData({
                                ...data,
                                uploadIntervalSeconds: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Scan Interval
                    <input
                        type="number"
                        value={data.scanInterval}
                        onChange={(e) =>
                            setData({
                                ...data,
                                scanInterval: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Scan Window
                    <input
                        type="number"
                        value={data.scanWindow}
                        onChange={(e) =>
                            setData({
                                ...data,
                                scanWindow: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Firmware Version
                    <input
                        type="string"
                        value={data.firmwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                firmwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="2.3.12"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Firmware Scan Filter
                    <input
                        type="string"
                        value={data.firmwareScanFilter || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                firmwareScanFilter: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="iBeacon/uuid"
                    />
                </label>
            </div>
        </div>
    </div>
);

// Export all sections
export {
    DeviceTypeSelector,
    BeaconBasicSection,
    LinkedItemSection,
    BeaconConfigSection,
    IBeaconSection,
    BeaconTriggersSection,
    BeaconVersionSection,
    GatewayBasicSection,
    GatewayDeviceInfoSection,
    ScanningSection,
    BeaconFramesConfig
};
