import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Activity,
    Bell,
    Bluetooth,
    Cpu,
    Info,
    Radio,
    Network,
    Shield,
    Server,
    Scan,
    ChevronLeft,
    ChevronRight,
    Save,
    X,
    CogIcon,
    Settings,
    FileCog,
    Focus,
    LucideBaggageClaim
} from 'lucide-react';
import styles from './DeviceForm.module.css';
import {
    DeviceTypeSelector,
    BeaconConfigSection,
    LinkedItemSection,
    GatewayBasicSection,
    ScanningSection,
    BeaconBasicSection,
    IBeaconSection,
    BeaconTriggersSection,
    BeaconVersionSection,
    BeaconFramesConfig,
    GatewayDeviceInfoSection
} from './DeviceFormSections';
import { Device, DeviceType } from '../App';
import { useDevices } from '../hooks/useDevices';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultBeacon, defaultGateway } from '../const';

const DeviceForm = ({}) => {
    const { addDevice, updateDevice, getDevice, devices } = useDevices();
    const navigateRouter = useNavigate();
    const [device, setDevice] = useState<Device | null>(null);
    const [deviceType, setDeviceType] = useState<DeviceType>(
        device?.type || 'beacon'
    );
    const [formData, setFormData] = useState<Device>(
        device || (deviceType === 'beacon' ? defaultBeacon : defaultGateway)
    );
    const { id } = useParams<{ id: string }>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [editingDevice, setEditingDevice] = useState<Device | null>(null);

    useEffect(() => {
        const fetchDeviceData = async () => {
            if (!id) {
                setIsLoaded(true);
                setEditingDevice(null);
                return;
            }
            const data = await getDevice(id);
            setDevice(data);
            setDeviceType(data.type);
            setEditingDevice(data);
            setFormData(data);
            setIsLoaded(true);
        };
        fetchDeviceData();
    }, [id]);

    const onBack = () => {
        navigateRouter(-1);
    };

    const onSave = async (device: Device) => {
        try {
            if (editingDevice) {
                const { deviceId, createdAt, updatedAt, ...deviceData } =
                    device;
                await updateDevice(editingDevice.deviceId, deviceData);
                navigateRouter(`/device/${device.deviceId}`);
            } else {
                await addDevice(device);
                navigateRouter(`/`);
            }
        } catch (err) {
            console.error('Failed to save device:', err);
            // Here you might want to show an error message to the user
        }
    };

    const [currentStep, setCurrentStep] = useState(device ? 1 : 0);
    const [direction, setDirection] = useState(0);

    // Define steps based on device type
    const steps =
        deviceType === 'beacon'
            ? [
                  { title: 'Type', icon: Bluetooth },
                  { title: 'Basic Info', icon: Info },
                  { title: 'Item Link', icon: LucideBaggageClaim },
                  { title: 'Config', icon: FileCog },
                  { title: 'Frames', icon: Focus },
                  { title: 'iBeacon', icon: Radio },

                  { title: 'Triggers', icon: Bell },
                  { title: 'Version Info', icon: Cpu }
              ]
            : [
                  { title: 'Choose Type', icon: Bluetooth },
                  { title: 'Basic Info', icon: Info },
                  { title: 'Hardware', icon: CogIcon },
                  //   { title: 'Network', icon: Network },
                  //   { title: 'Security', icon: Shield },
                  { title: 'Scanning', icon: Scan }
                  //   { title: 'Server', icon: Server },
                  //   { title: 'Version Info', icon: Cpu }
              ];

    // Filter out steps that shouldn't be shown
    const activeSteps = steps;

    const renderStepContent = (step: number): JSX.Element | null => {
        if (deviceType === 'beacon') {
            switch (step) {
                case 0:
                    return (
                        <DeviceTypeSelector
                            selectedType={deviceType}
                            onTypeChange={handleTypeChange}
                        />
                    );
                case 1:
                    return (
                        <BeaconBasicSection
                            data={formData}
                            setData={setFormData}
                            allDevices={devices}
                        />
                    );
                case 2:
                    return (
                        <LinkedItemSection
                            data={formData}
                            setData={setFormData}
                        />
                    );
                case 3:
                    return (
                        <BeaconConfigSection
                            data={formData}
                            setData={setFormData}
                        />
                    );
                case 4:
                    return (
                        <BeaconFramesConfig
                            data={formData}
                            setData={setFormData}
                        />
                    );
                case 5:
                    return (
                        <IBeaconSection data={formData} setData={setFormData} />
                    );
                case 6:
                    return (
                        <BeaconTriggersSection
                            data={formData}
                            setData={setFormData}
                        />
                    );
                case 7:
                    return (
                        <BeaconVersionSection
                            data={formData}
                            setData={setFormData}
                        />
                    );
                default:
                    return null;
            }
        } else {
            switch (step) {
                case 0:
                    return (
                        <DeviceTypeSelector
                            selectedType={deviceType}
                            onTypeChange={handleTypeChange}
                        />
                    );
                case 1:
                    return (
                        <GatewayBasicSection
                            data={formData}
                            setData={setFormData}
                            allDevices={devices}
                        />
                    );
                case 2:
                    return (
                        <GatewayDeviceInfoSection
                            data={formData}
                            setData={setFormData}
                        />
                    );

                case 3:
                    return (
                        <ScanningSection
                            data={formData}
                            setData={setFormData}
                        />
                    );

                default:
                    return null;
            }
        }
    };

    const handleTypeChange = (type: DeviceType) => {
        setDeviceType(type);
        setFormData(type === 'beacon' ? defaultBeacon : defaultGateway);
    };

    const navigate = (delta: number) => {
        const newStep = currentStep + delta;
        if (newStep >= 0 && newStep < activeSteps.length) {
            setDirection(delta);
            setCurrentStep(newStep);
        }
    };

    // Add loading states where needed:
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log(isSubmitting);
        setIsSubmitting(true);
        try {
            await onSave(formData);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (show message to user, etc.)
        } finally {
            setIsSubmitting(false);
        }
    };
    const StepIcon = activeSteps[currentStep].icon;

    return (
        <div className={styles.formContainer}>
            {/* Header */}
            <div className={styles.formHeader}>
                <button onClick={onBack} className={styles.backButton}>
                    <ChevronLeft size={20} />
                    <span>Back</span>
                </button>
                <h1 className={styles.formTitle}>
                    {device ? 'Edit Device' : 'Add Device'}
                </h1>
            </div>

            {/* Form Content */}
            <form onSubmit={handleSubmit}>
                <div className={styles.formContent}>
                    <AnimatePresence mode="wait" custom={direction}>
                        {isLoaded && (
                            <motion.div
                                key={currentStep}
                                custom={direction}
                                initial={{
                                    opacity: 0,
                                    y: direction > 0 ? 20 : -20
                                }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{
                                    opacity: 0,
                                    y: direction > 0 ? -20 : 20
                                }}
                                transition={{ duration: 0.2 }}
                                className={styles.formStep}>
                                {renderStepContent(currentStep)}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                {/* Bottom Navigation */}
                <div className={styles.formNavigation}>
                    <div className={styles.tabContainer}>
                        {activeSteps.map((step, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={() => {
                                    setDirection(index - currentStep);
                                    setCurrentStep(index);
                                }}
                                className={`${styles.tabButton} ${index === currentStep ? styles.active : ''}`}>
                                <div className={styles.tabIcon}>
                                    <step.icon size={20} />
                                </div>
                                <span className={styles.tabLabel}>
                                    {step.title}
                                </span>
                                {index === currentStep && (
                                    <motion.div
                                        className={styles.tabHighlight}
                                        layoutId="tabHighlight"
                                    />
                                )}
                            </button>
                        ))}
                    </div>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`${styles.saveButton} ${isSubmitting ? styles.isLoading : ''}`}>
                        <div className={styles.sparkleContainer}>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                        </div>
                        <Save size={20} />
                        <span>{device ? 'Update Device' : 'Save Device'}</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DeviceForm;
