import React from 'react';
import { Clock } from 'lucide-react';
import styles from './DeviceStateIndicator.module.css';

export const getTimeAgo = (lastSeen: string | number | Date) => {
    const now = new Date();
    const seenDate = new Date(
        Date.UTC(
            new Date(lastSeen).getUTCFullYear(),
            new Date(lastSeen).getUTCMonth(),
            new Date(lastSeen).getUTCDate(),
            new Date(lastSeen).getUTCHours(),
            new Date(lastSeen).getUTCMinutes(),
            new Date(lastSeen).getUTCSeconds()
        )
    );
    const diffInSeconds = Math.floor(
        (now.getTime() - seenDate.getTime()) / 1000
    );

    if (diffInSeconds < 60) return `${diffInSeconds}s`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
};

// Helper function to determine if device was recently offline (within last hour)
const isRecentlyOffline = (lastSeen: number | undefined): boolean => {
    if (!lastSeen) return false;
    const now = new Date();
    const seenDate = new Date(lastSeen);
    const diffInSeconds = Math.floor(
        (now.getTime() - seenDate.getTime()) / 1000
    );
    return diffInSeconds <= 3600; // One hour in seconds
};

interface DeviceStateIndicatorProps {
    isOnline: boolean;
    lastSeen: number | undefined;
}

const DeviceStateIndicator: React.FC<DeviceStateIndicatorProps> = ({
    isOnline,
    lastSeen
}) => {
    const showLastSeen = lastSeen !== undefined && lastSeen !== 0;
    const recentlyOffline =
        !isOnline && showLastSeen && isRecentlyOffline(lastSeen);

    return (
        <div className={styles.statusContainer}>
            <div
                className={`${styles.statusBadge} ${
                    isOnline
                        ? styles.statusBadgeOnline
                        : recentlyOffline
                          ? styles.statusBadgeRecentlyOffline
                          : styles.statusBadgeOffline
                }`}>
                <div
                    className={`${styles.dot} ${
                        isOnline
                            ? styles.online
                            : recentlyOffline
                              ? styles.recentlyOffline
                              : styles.offline
                    }`}
                />
                <span
                    className={`${styles.statusText} ${
                        isOnline
                            ? styles.onlineText
                            : recentlyOffline
                              ? styles.recentlyOfflineText
                              : styles.offlineText
                    }`}>
                    {isOnline ? 'ONLINE' : 'OFFLINE'}
                </span>
            </div>

            {showLastSeen && (
                <div
                    className={`${styles.lastSeen} ${
                        isOnline
                            ? styles.lastSeenOnline
                            : recentlyOffline
                              ? styles.lastSeenRecentlyOffline
                              : styles.lastSeenOffline
                    }`}>
                    <Clock className={styles.clockIcon} />
                    <span>{getTimeAgo(lastSeen)}</span>
                </div>
            )}
        </div>
    );
};

export default DeviceStateIndicator;
