import { Device } from '../App';

const API_BASE_URL = 'https://meii.minji.pack.wtf/api';
// const API_BASE_URL = 'http://localhost:3001/api';

// Helper to handle API responses
async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    // If the fetch fails and we're offline, the service worker
    // will automatically return cached data if available
    if (!navigator.onLine) {
      console.log('Offline - using cached data');
    } else {
      const error = await response.json();
      const statusCode = response.status;
      if (statusCode === 401) {
        localStorage.removeItem('token');
        //@ts-ignore
        window.location = '/';
        throw new Error('Unauthorized');
      }
      const errorMessage = (error as any).error || 'An error occurred';
      throw new Error(errorMessage);
    }
  }
  return response.json() as Promise<T>;
}

function getAuthHeaders() {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
}

export const deviceService = {
  // Get all devices
  getAllDevices: async (): Promise<Device[]> => {
    const response = await fetch(`${API_BASE_URL}/devices`, {
      method: 'GET',
      headers: getAuthHeaders()
    });

    if (!response.ok) {
      if (!navigator.onLine) {
        console.log('Offline - using cached data');
      } else {
        const error = await response.json();
        const statusCode = response.status;
        if (statusCode === 401) {
          throw new Error('Unauthorized');
        }
        const errorMessage = (error as any).error || 'An error occurred';
        throw new Error(errorMessage);
      }

      return [];
    }

    return response.json() as Promise<Device[]>;
  },

  // Get single device
  getDevice: async (id: string): Promise<Device> => {
    const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
      method: 'GET',
      headers: getAuthHeaders()
    });
    return handleResponse<Device>(response);
  },

  // Create new device
  createDevice: async (device: Device): Promise<Device> => {
    console.log(device);

    const createDevice = {
      ...device
    };
    //@ts-ignore
    delete createDevice.createdAt;
    //@ts-ignore
    delete createDevice.updatedAt;
    //@ts-ignore
    delete createDevice._id;
    //@ts-ignore
    delete createDevice.__v;
    //@ts-ignore
    delete createDevice.logs;
    //@ts-ignore
    delete createDevice.locationHistory;
    delete createDevice.lastSeenCity;
    //@ts-ignore
    delete createDevice.lastSeenCountry;

    delete createDevice.lastSeen;

    const response = await fetch(`${API_BASE_URL}/devices`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(createDevice)
    });
    return handleResponse<Device>(response);
  },

  // Update device
  updateDevice: async (id: string, device: Partial<Device>): Promise<Device> => {
    const updatedDevice = {
      ...device
    };

    delete updatedDevice.createdAt;
    delete updatedDevice.updatedAt;
    //@ts-ignore
    delete updatedDevice._id;
    //@ts-ignore
    delete updatedDevice.__v;
    //@ts-ignore
    delete updatedDevice.logs;
    //@ts-ignore
    delete updatedDevice.locationHistory;
    delete updatedDevice.lastSeenCity;
    //@ts-ignore
    delete updatedDevice.lastSeenCountry;

    delete updatedDevice.lastSeen;

    const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
      method: 'PUT',
      headers: getAuthHeaders(),
      body: JSON.stringify(updatedDevice)
    });
    return handleResponse<Device>(response);
  },

  // Delete device
  deleteDevice: async (id: string): Promise<void> => {
    const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
      method: 'DELETE',
      headers: getAuthHeaders()
    });
    return handleResponse<void>(response);
  }
};
